<template lang="pug">
div
  quote-info(ref='quote' :value='quoteData')
  van-submit-bar(:button-text='submitBarText', @submit='onSubmit' :disabled='commitState')
  //- van-submit-bar(:price="totalPrice" :button-text='submitBarText', @submit='onSubmit')
  loading-view(:value="loadingState")
</template>

<script>
import { 
  SubmitBar, 
} from 'vant'

import formmixin from '../../components/form'


export default {
  mixins: [formmixin],
  props: [
    'rooms',
    'totalPrice',
    'quoteId'
  ],
  components: {
    [SubmitBar.name]: SubmitBar,
  },
  data() {
    return {
      loadingState: false,
      commitState: false,
      quoteData: {
        title: '',
        code: '',
        remark: '',
        cus_com_poi_ofc_cus_companys: 0,
        company_name: '',
        receipt: '',
        tel: '',
        address: '',
        factory_discount_price: 0,      // 出厂价
        factory_total_price: 0          // 产品价格
      },
      submitBarText: '生成报价单'
    };
  },
  methods: {
    async onSubmit() {
      let data = this.$refs.quote.getData()
      try {
        if(data.cus_com_poi_ofc_cus_companys === '' || data.cus_com_poi_ofc_cus_companys == '0' ) {
          this.$toast('请选择服务公司')
          return 0
        }
        if(data.address === '') {
          this.$toast('请选择客户地址')
          return 0
        }
        if(data.title === '') {
          this.$toast('请填写报价单名称')
          return 0
        }
        if(data.quote_rel_quote_item.length === 0){
          this.$toast('结算商品为空')
          return 0
        }
        this.commitState = true
        if(this.quoteId == 0) {

          let temp_factory_discount_price = data.quote_rel_quote_item.reduce((pre,cur) => {
            return pre + parseFloat(cur.factory_price) * parseFloat(cur.num)
          },0).toFixed(2)
          data.factory_discount_price = temp_factory_discount_price
          data.factory_total_price = data.factory_discount_price 
          await this.$api.CreateQuote(data)
        } else {
          data.id = this.quoteId
          await this.$api.UpdateQuote(data)
          // let data2 = {
          //   flow: "quote_state",
          //   flow_poi_flow: 32,
          //   flow_remark: "",
          //   id: this.quoteId,
          //   status: "offering",
          //   version: "1.0"
          // }
          // await this.$api.QuoteFlowUpdate(data2)
        }
        this.$toast('提交报价单成功')
        // this.$router.push({name: 'offer' })
      } catch (e) {
        this.$toast(this.$error(e))
      }
      this.commitState = false
    },

    // 格式化item数据
    parseQuoteItems() {
      let items = []
      let quoteItemId = -1
      let newId = 1
      let order = 0
      this.rooms.map((group) => {
        let selectGoods = group.goods.filter((goods) => {
          return group.checkedGoods.indexOf(goods.id) > -1
        })
        newId++
        selectGoods.map((good) => {
          items.push({
            id: quoteItemId--,
            quote_poi_quote: this.quoteId,
            sku_poi_furniture_sku: good.origin.sku_poi_furniture_sku,
            fur_id_poi_furnitures: good.origin.fur_id_poi_furnitures,
            price: good.origin.furniture_sku.price,
            factory_price: good.origin.furniture_sku.factory_price,
            cost_price: good.origin.furniture_sku.cost_price,
            num: good.num,
            subtotal: (good.num * parseFloat(good.price)).toFixed(2),
            type: group.type,
            fur_name: good.origin.furnitures.fur_name,
            code: good.origin.furnitures.fur_code,
            sizes: good.origin.furniture_sku.size,
            color: good.origin.furniture_sku.color,
            mask_delete: good.origin.mask_delete,
            thumbnail_url: good.origin.furniture_sku.thumbnail,
            version: good.origin.furniture_sku.version,
            order: order++,
            remark: good.origin.furniture_sku.desc,
            project: group.name,
            group: newId
          })
        })
      })
      return items
    },

    // 获取报价单
    async getQuote() {
      let params = {
        id: this.quoteId,
        with: {
          relation: [
            {
              table: "quote_item",
              key: "quote_poi_quote", 
              needcount: 0,
              order: "order"
            }
          ],
        }
      }
      try{
        let res = await this.$api.GetQuoteNew(params)
        return res.data
      } catch(ex) {
        return []
      }
    }
  },
  async mounted() {
    let data = this.parseQuoteItems()
    if (this.quoteId > 0) {
      this.loadingState = true
      let currentData = await this.getQuote()
      let tempItems = currentData.quote_rel_quote_item
      data.map((item) => {
        let findItem = tempItems.find(p=> p.project == item.project && p.sku_poi_furniture_sku == item.sku_poi_furniture_sku)
        if (findItem) {
          findItem.num = parseInt(findItem.num) + parseInt(item.num)
          findItem.subtotal = (parseInt(findItem.num) * parseFloat(item.price)).toFixed(2)
        } else {
          let group = tempItems.find(p=> p.project == item.project)
          if (group) {
            item.group = group.group
          }
          tempItems.unshift(item)
        }
      })
      this.quoteData = currentData
      this.quoteData.quote_rel_quote_item = tempItems
      this.loadingState = false
    } else {
      this.$set(this.quoteData, 'quote_rel_quote_item', data)
    }
  }
};
</script>

<style lang="less">
.card-goods {
  padding: 10px 0;
  background-color: #fff;
  &__item {
    position: relative;
    background-color: #fafafa;
    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }
    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }
    .van-card__price {
      color: #f44;
    }
  }
}
</style>